.result-content {
  background-color: yellow;
  border: 2px black solid;
  width: 60%;
  margin: auto;
}

.result-list {
  list-style: none;
  padding-left: 0;
}

.result-title {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.3rem;
}

.missing-message {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.3rem;
}

.missing-image {
  width: 30%;
  border: 2px solid black;
}

.result {
  font-size: 1.3rem;
  
}

.results-button {
  margin-bottom: 10px;
  margin-left: 5px;
  width: 30%;
  height: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  border: 2px yellow solid;
  background-color: black;
  color: yellow;
  font-size: 1.2rem;
}

.results-button > a {
  text-decoration: none;
  color: yellow;
}

.results-button > a:visited {
  color: yellow;
}