.main-page-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 30%;
  font-family: 'Roboto', sans-serif;
  color: yellow;
  text-shadow:
       2px 2px 0 #000,
     -2px -2px 0 #000,  
      2px -2px 0 #000,
      -2px 2px 0 #000,
       2px 2px 0 #000;
  margin: auto;
}

.main-page-image {
  margin-bottom: 10%;
  border: 2px yellow solid;
}

.search-button {
  width: 100%;
  height: 50px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  border: 2px yellow solid;
  background-color: black;
  color: yellow;
}