.search-content {
  width: 25%;
  background-color: yellow;
  border: 2px black solid;
  margin: auto;
}

.error-message {
  color: red;
  font-size: .5rem;
}

.search-label {
  font-family: 'Press Start 2P', cursive;
}

.search-form {
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 5%;
}

.search-field {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 1.5rem;
}

.search-button-input {
  width: 100%;
  height: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  border: 2px yellow solid;
  background-color: black;
  color: yellow;
  font-size: 1.2rem;
}

.search-img {
  height: 15%;
  width: 15%;
}