h1 {
  color: yellow;
  font-family: 'Press Start 2P', cursive;
  font-size: 4rem;
  -webkit-text-stroke: 1px black;
   text-shadow:
       2px 2px 0 #000,
     -2px -2px 0 #000,  
      2px -2px 0 #000,
      -2px 2px 0 #000,
       2px 2px 0 #000;
}